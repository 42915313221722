.event__admin__search {
    position: relative;

    .search-by-region-input {
        margin-right: 10px;
        width: 25%;

        @media (min-width: 991px) and (max-width: 1024px) {
            width: 26%;
        }

        @media (min-width: 568px) and (max-width: 767px) {
            width: 59%;
            margin-top: 10px;
        }

        @media (min-width: 320px) and (max-width: 567px) {
            margin-right: 0px;
            width: 100%;
            margin-bottom: 10px;
        }
    }

    .pagefilter-search-button {
        @media (min-width: 768px) and (max-width: 1200px) {
            .outline-btn {
                i {
                    display: none;
                }
            }
        }
    }

    .pagefilter__addbtn {
        margin-left: auto;

        @media (min-width: 568px) and (max-width: 767px) {
            margin-top: 0;
            position: absolute;
            right: 15px;
            bottom: 10px;
        }

        .outline-btn {
            padding: 9px 15px;
        }
    }
}

.send-invitation-request-pop{
    .exhibitors-row-2{
        .col-sm-6 {
            @media (min-width: 768px) and (max-width: 991px) {
                -ms-flex: 0 0 100%;
                flex: 0 0 100%;
                max-width: 100%;
            }
           
        }
    }
}

.event-stus-ul.cursor_css ul li{
    cursor: pointer;
    &:hover{
        background: #f1f1f1;
        color: #000;
        strong{
            color: #000;
        }
    }
}

.event-detail-hd span{
    font-size: 24px;
    font-weight: 400;
    font-family: 'prototyperegular'; 
    @media (min-width: 320px) and (max-width: 767px) {
        font-size: 17px;
    }
}