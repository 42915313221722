.custom-time-zone-box{
  position: relative;
  width: 100%;
  .custom-time-zone-box-top{
      border: 1px #ccc solid;
      background-color: #fff;
      border-radius: 3px;
      font-size: 15px;
      color: #000;
      font-weight: 400;
      padding:0px 10px;
      display: flex;
      align-items: center;
      .custom-time-zone-box-top-l{
          width: calc(100% - 60px);
          position: relative;
          .custom-time-zone-serach{
              
              input{
                  background: none transparent;
                  border: 0;
                  box-shadow: none;
                  box-sizing: content-box;
                  cursor: default;
                  outline: none;
                  padding: 13px 13px 13px 0;
                  width: 100%;
                  font-size: 15px;
                  color: #000;
                  font-weight: 400;
              }
          }
      }
      .custom-time-zone-box-top-r{
          margin-left: auto;
          display: flex;
          align-items: center;
          button{
              margin-left: 10px;
              background-color: transparent;
              border: 0px;
              padding: 0px;
              font-size: 18px;
              color: #999;
              cursor: pointer;
             
              &.custom-arrow-wrapper-top{
                  width: 0; 
                  height: 0; 
                  border-left: 6px solid transparent;
                  border-right: 6px solid transparent;
                  border-bottom: 6px solid #999;
              }
              &.custom-arrow-wrapper-btm{
                  width: 0; 
                  height: 0; 
                  border-left: 6px solid transparent;
                  border-right: 6px solid transparent;
                  border-top: 6px solid #999;
              }
          }
      }
  }
  .custom-time-zone-box-btm{
      border: 1px #ccc solid;
      background-color: #fff;
      border-radius:0 0 3px 3px;
      border-top: 0px;
      .custom-time-zone-scroll{
          max-height: unset;
          overflow: hidden;
          transition: max-height 0.3s ease-in-out;
          &.scrollable {
              max-height: 222px; /* Adjust the height as needed */
              overflow-y: auto; /* Adds vertical scroll when content overflows */
              scroll-behavior: smooth;
          }
      }
       ul{
          margin: 0px;
          padding: 0px;
          list-style: none;
          &:nth-child(2){
              border-top: 1px #D9D9D9 solid;
              margin-top: 10px;
          }
      
         
          li{
              margin: 0px;
              padding:8px 13px;
              font-size: 14px;
              color: #000;
              &:hover,
              &.active{
                  background-color: #DBEDF7;

              }
          }
       }
       .custom-time-zone-btns{
          border-top: 1px #D9D9D9 solid;
          padding: 10px 13px;
          button{
              background-color: transparent;
              border: 0px;
              padding: 0px;
              font-size: 14px;
              color: #7266ED;
              cursor: pointer ; 
              font-weight: 500;
          }
       }
  }

}