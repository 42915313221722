/* You can add global styles to this file, and also import other style files */
@import "~angular-notifier/styles";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "assets/sass/page-sass/event-filter";
@import "assets/sass/page-sass/custom-time-zone-box";

.phone-number-input-box {
    .ng-select {
        .ng-select-container {
            border: 0px !important;
            border-right: 1px solid #cccccc !important;
            border-radius: 3px 0 0 3px;
            &.ng-select-focused{
                outline: 0px !important;
                border: 0px !important;
                border-color:transparent !important;
                box-shadow:inset 0 1px 1px rgba(0,0,0,0.0),0 0 0 3px rgba(0,126,255,0.0) !important;
            }
        }
    }
    ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container{
        border-color:transparent !important;
        box-shadow:inset 0 1px 1px rgba(0,0,0,0.0),0 0 0 3px rgba(0,126,255,0.0) !important;
        border-right: 1px solid #cccccc !important;
    }
}


.remove-attendees{
    padding-right: 15px;
    text-align: right;
   button{
    background: #fff;
    border: 1px #7266ed solid;
    border-radius: 25px;
    color: #7266ed;
    font-size: 14px;
    padding: 5px 10px;
    &:hover{
        background: #000;
        color: #fff;
        border: 1px #000 solid;
    }
   }
  
}
.pagefilter__addbtn{
    .show{
        .dropdown-toggle::after {
            display: inline-block;
            border-top: 0;
            border-right: 0.3em solid transparent;
            border-bottom: 0.3em solid;
            border-left: 0.3em solid transparent;
        }
    }
}
.phone-code-label .ng-input input{
    color: #333;
    font-weight: 300 !important; 
  }
  
  .event-detail-hd ul li span{
    font-family: "Roboto", sans-serif !important;
    font-weight: 400;
  }

  .orange_bg{
    background: #ffe5d1 !important;
    border: 1px #f89243 solid !important;
    font-size: 15px !important;
    color: #000 !important;
  }
  .blue_bg{
    background: #dbeef7 !important;
    border: 1px #02a4e0 solid !important;
    font-size: 15px !important;
    color: #000 !important;
  }
  .custom_error{
    font-weight: 300;
    font-size: 12px;
  }
  .login-width .login-right .login-input{
    margin-bottom: 15px;
  }
  .login-width .login-right input.login-field{
    margin-bottom: 0px;
  }